


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatNumber } from 'libphonenumber-js';

@Component({})
export default class PhoneRow extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: String }) labelInfo?: string;
  @Prop({ type: String }) icon?: string;
  @Prop({ type: Boolean }) tooltip?: boolean;
  @Prop({ default: 'indigo' }) iconColor!: string;

  get number(): string {
    if (!this.value) return '';

    return formatNumber(this.value, 'DE', 'E.164') || this.value;
  }

  format(number: string): string {
    return formatNumber({ country: 'DE', phone: number }, 'National') || number;
  }
}
