

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ContactHeaderCard extends Vue {
  @Prop({ type: String, required: true }) headline!: string;
  @Prop({ type: String, required: true }) emptyText!: string;
  @Prop({ type: Boolean, default: true }) showInfo!: boolean;
}
