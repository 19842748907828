
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import PhoneRow from '@/components/PhoneRow.vue';
import CardRow from '@/components/CardRow.vue';
import { Contact } from '@/modules/conservatorshipToContact/types';

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');
const ContactPersonsDialog = () => import('@/modules/conservatorshipToContact/components/ContactPersonsDialog.vue');

@Component({
  components: {
    ContactPersonsDialog,
    CreateLetterButton,
    PhoneRow,
    CardRow
  }
})
export default class ContactCard extends Vue {
  @Prop({ type: Object, required: true }) contact!: Contact;
  @Prop({ type: String, required: true }) conservatorship!: string;
}
