















import { Component, Prop, Vue } from 'vue-property-decorator';
import { contactTypes } from '../helper';

@Component({
  inheritAttrs: false
})
export default class ContactTypeSelect extends Vue {
  @Prop({ type: [String, Array] }) value?: string | string[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: true }) required!: boolean;

  get list() {
    return contactTypes();
  }

  created() {
    this.$emit('input', this.value);
  }
}
