import {
  AddDepartment,
  Contact,
  Department,
  UpdateDepartment,
  WriteContact
} from '@/modules/conservatorshipToContact/types';
import uuid from 'uuid/v4';
import { Type, WriteContact as ChangeContact } from '@/modules/contact/types';

export const createContact = (conservatorship_id: string, types: Type[], contact_id = uuid()): WriteContact => ({
  conservatorship_id,
  contact_id,
  name: '',
  additional: '',
  street: '',
  city: '',
  zip_code: '',
  phone_number: '',
  note: '',
  types
});

export const createDepartment = (conservatorship_id: string, contact_id = uuid()): AddDepartment | UpdateDepartment => ({
  conservatorship_id,
  contact_id,
  customer_number: '',
  type: null
});

export const MapContactToChangeContact = (contact: Contact): ChangeContact => ({
  contact_id: contact.id,
  name: contact.name,
  additional: contact.additional,
  street: contact.street,
  zip_code: contact.zipCode,
  city: contact.city,
  note: contact.note,
  types: contact.types,
  phone_number: contact.phoneNumber
});


export const MapDepartmentToUpdateDepartment = (department: Department): UpdateDepartment => ({
  contact_id: department.id,
  conservatorship_id: department.conservatorshipId,
  customer_number: department.customerNumber,
  type: department.type
});
