


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_CONTACT } from '../../contact/store';
import { REMOVE_CONTACT } from '../store';
import { Contact, RemoveContact } from '../types';
import { WriteContact } from '../../contact/types';
import { ApiResponse } from '@/components/types';
import { MapContactToChangeContact } from '@/modules/conservatorshipToContact/model';
import ContactTypeSelect from '@/modules/contact/components/ContactTypeSelect.vue';

const Contact = namespace('contact');
const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: {
    ContactTypeSelect
  }
})
export default class ChangeDoctorDialog extends Vue {
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Prop({ type: Object }) contact?: Contact | null;
  @Prop({ type: Boolean, default: false }) showType!: boolean;
  @Contact.Action(CHANGE_CONTACT) changeContact!: (contact: WriteContact) => Promise<ApiResponse>;
  @Conservatorship.Action(REMOVE_CONTACT) removeContact!: (contact: RemoveContact) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: WriteContact = {
    contact_id: '',
    name: '',
    additional: '',
    street: '',
    zip_code: '',
    city: '',
    note: '',
    types: [],
    phone_number: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.value = {
        contact_id: '',
        name: '',
        additional: '',
        street: '',
        zip_code: '',
        city: '',
        note: '',
        types: [],
        phone_number: ''
      };

      this.error = null;
      this.$emit('update:contact', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('contact')
  watchContact(contact: Contact | null) {
    if (!contact) return;

    this.value = MapContactToChangeContact(contact);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeContact(this.value);
  }

  remove() {
    if (!this.contact) return;

    this.error = null;

    return this.removeContact({
      contact_id: this.contact.id,
      conservatorship_id: this.conservatorship
    });
  }
}
